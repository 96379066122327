
import { defineComponent } from 'vue'
import axios from 'axios'

export default defineComponent({
  name: 'BetaSignup',
  data () {
    return {
      isSubmitted: false,
      email: ''
    }
  },
  methods: {
    handleSubmit () {
      axios
        .create({
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          transformRequest: [(data, _headers) => JSON.stringify(data)]
        })
        .post('https://hooks.zapier.com/hooks/catch/3747051/olkz0jl/silent/', {
          email: this.email
        })

      this.isSubmitted = true
    }
  }
})
